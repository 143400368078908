import { AppPage } from '../../components/app_page';
import { calculateBlackJackHandValue, CardComponent } from './card';
import './card_games.css';
import {
  BlackjackProvider,
  useBlackjack,
} from './black_jack/black_jack_provider';

function GameScreen() {
  return (
    <AppPage>
      <div className="game-container">
        <BlackJackGame></BlackJackGame>
      </div>
    </AppPage>
  );
}

export function BlackJackGame() {
  const PlayerHand = () => {
    const { playerHand } = useBlackjack();
    return (
      <div>
        <h2>Player's Hand</h2>
        <div className="card-hand-container">
          {playerHand.map((card, index) => (
            <CardComponent key={index} value={card.value} suit={card.suit} />
          ))}
        </div>
        <p>Total Value: {calculateBlackJackHandValue(playerHand)}</p>
      </div>
    );
  };

  const DealerHand = () => {
    const { dealerHand } = useBlackjack();
    return (
      <div>
        <h2>Dealer's Hand</h2>
        <div className="card-hand-container">
          {dealerHand.map((card, index) => {
            if (card === undefined) {
              return <div />;
            }
            return (
              <CardComponent key={index} value={card.value} suit={card.suit} />
            );
          })}
        </div>
        <p>Total Value: {calculateBlackJackHandValue(dealerHand)}</p>
      </div>
    );
  };

  const Controls = () => {
    const { playerMove, startNewGame, gameStatus } = useBlackjack();

    return (
      <div>
        {gameStatus === 'playing' ? (
          <>
            <button onClick={() => playerMove('hit')}>Hit</button>
            <button onClick={() => playerMove('stand')}>Stand</button>
          </>
        ) : (
          <button onClick={startNewGame}>Start New Game</button>
        )}
      </div>
    );
  };

  const GameStatus = () => {
    const { gameStatus } = useBlackjack();
    return <h2>{gameStatus.toUpperCase()}</h2>;
  };
  return (
    <BlackjackProvider>
      <PlayerHand />
      <DealerHand />
      <Controls />
      <GameStatus />
    </BlackjackProvider>
  );
}

export default GameScreen;
