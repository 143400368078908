import Landing from './pages/landing/landing';
import Contact from './pages/contact/contact';
import Projects from './pages/projects/projects';
import UserEntryModal from './pages/onboarding/user_entry';
import AYS from './pages/projects/project_pages/ays/ays';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './index.css';
import Navbar from './components/navigation/navbar';
import Footer from './components/footer/footer';
import { DarkModeProvider } from './components/dark_mode_provider';
import GameScreen from './pages/card_games/card_games';
import { AuthProvider } from './providers/auth_provider';
import React, { useState } from 'react';

function App() {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const toggleAuthModal = () => {
    setShowAuthModal(!showAuthModal);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AuthProvider>
        <DarkModeProvider>
          <Router>
            <Navbar handleOpenAuthModal={toggleAuthModal} />
            <UserEntryModal
              show={showAuthModal}
              handleClose={toggleAuthModal}
            />
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/games" element={<GameScreen />} />
              <Route path="/projects/ays" element={<AYS />} />
              <Route path="*" element={<Contact />} />
            </Routes>
          </Router>

          <Footer />
        </DarkModeProvider>
      </AuthProvider>
    </div>
  );
}
export default App;
