export function VerticalSpace({ spacing }) {
  const style = {
    height: `${spacing}px`,
  };
  return <div style={style}></div>;
}

export function HorizontalSpace({ spacing }) {
  const style = {
    width: `${spacing}px`,
  };
  return <div style={style}></div>;
}
