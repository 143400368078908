import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { ImGithub } from 'react-icons/im';
import { FaLinkedin } from 'react-icons/fa';
import { useDarkMode } from '../../components/dark_mode_provider';
import './footer_styles.css';
import { HorizontalSpace, VerticalSpace } from '../spacing';

const Footer = () => {
  const { darkMode } = useDarkMode();
  return (
    <div className={`footer-container ${darkMode && 'footer-container-dark'}`}>
      <div className="footer-content">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <Map
            style={{
              width: '125px',
              height: '125px',
              borderRadius: '50%',
              overflow: 'hidden',
            }}
            defaultCenter={{ lat: 26.640629, lng: -81.872307 }}
            defaultZoom={13}
            disableDefaultUI={true}
          />
        </APIProvider>
        <VerticalSpace spacing={30} />
        <div style={{ fontWeight: 'bold' }}>Based in Fort Myers</div>
      </div>
      <div className="footer-content">
        <div style={{ fontWeight: 'bold', fontSize: 24 }}>Socials</div>
        <VerticalSpace spacing={30} />
        <div className="footer-icons">
          <a
            href="https://github.com/Raymondjp33"
            style={{ textDecoration: 'none', color: 'inherit' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImGithub size={35} />
          </a>
          <HorizontalSpace spacing={20} />
          <a
            href="https://www.linkedin.com/in/raymond-price-251248239/"
            style={{ textDecoration: 'none', color: 'inherit' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={35} />
          </a>
        </div>
      </div>
      <div className="footer-content">
        <a
          href="mailto:RaymondJPrice33@gmail.com"
          style={{ textDecoration: 'underline', color: 'inherit' }}
        >
          RaymondJPrice33@gmail.com
        </a>
        <VerticalSpace spacing={30} />
        <a
          href="tel:+7727080425"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          772 - 708 - 0425
        </a>
      </div>
    </div>
  );
};

export default Footer;
