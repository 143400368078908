export function Row({ children }) {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return <div style={style}>{children}</div>;
}

export function Spacer() {
  const style = {
    flex: '1',
  };
  return <div style={style}></div>;
}
