import TextField from '../../components/text_field';
import { HorizontalSpace, VerticalSpace } from '../../components/spacing';
import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useDarkMode } from '../../components/dark_mode_provider';
import { Helpers } from '../../services/helpers';
import { CloudFunctions } from '../../services/cloud_functions';
import './user_entry.css';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { IoMdClose } from 'react-icons/io';

function UserEntryModal({ show, handleClose }) {
  const { darkMode } = useDarkMode();
  const [formError, setFormError] = useState('');
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [viewingLogin, setViewingLogin] = useState(false);
  const [entryLoading, setEntryLoading] = useState(false);

  const checkFormErrors = () => {
    if (formValues.email === '') {
      setFormError('You must provide your email!');
      return true;
    }
    if (!Helpers.isValidEmail(formValues.email)) {
      setFormError('Please enter a valid email address!');
      return true;
    }
    if (formValues.password === '') {
      setFormError('You must provide a password!');
      return true;
    }
    if (!viewingLogin && formValues.password !== formValues.confirmPassword) {
      setFormError('Passwords do not match!');
      return true;
    }
    setFormError('');
    return false;
  };

  const handleChange = (event) => {
    if (formError !== '') {
      setFormError('');
    }
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    await e.preventDefault();
    if (checkFormErrors()) {
      return;
    }

    setEntryLoading(true);

    if (viewingLogin) {
      handleLogin();
      return;
    }

    const result = await CloudFunctions.userSignUp({
      email: formValues.email,
      password: formValues.password,
    });

    if (
      result.data.error !== undefined &&
      typeof result.data.error === String
    ) {
      setFormError(result.data.error);
      setEntryLoading(false);
      return;
    }

    handleLogin();
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(
        auth,
        formValues.email,
        formValues.password
      );
    } catch (err) {
      setFormError(err.message);
      setEntryLoading(false);
      return;
    }

    setEntryLoading(false);
    handleClose();
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const handleSwitchView = () => {
    setViewingLogin(!viewingLogin);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="auth-modal-wrapper" onClick={handleOverlayClick}>
      <div
        className={`auth-modal-container  ${
          darkMode && 'auth-modal-container-dark'
        }`}
      >
        <div className="auth-modal-header">
          <div style={{ width: '25px' }}></div>
          {viewingLogin ? 'Login' : 'Sign Up'}
          <div style={{ width: '25px' }}>
            <IoMdClose onClick={handleClose} cursor={'pointer'} />
          </div>
        </div>
        <VerticalSpace spacing={20} />
        <div style={{ fontSize: '16px', fontWeight: '400' }}>
          Proceed through a fully functioning authentication flow!
        </div>
        <VerticalSpace spacing={10} />
        <ViewSwitcherComponent
          handleSwitchView={handleSwitchView}
          viewingLogin={viewingLogin}
        />
        <VerticalSpace spacing={50} />
        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'left' }}>Email</div>
          <VerticalSpace spacing={10} />
          <TextField
            className={`auth-modal-textfield ${
              darkMode && 'auth-modal-textfield-dark'
            }`}
            placeholder="Email Address"
            name="email"
            value={formValues.email}
            onChange={handleChange}
          />
          <VerticalSpace spacing={20} />
          <div style={{ textAlign: 'left' }}>Password</div>
          <VerticalSpace spacing={10} />
          <TextField
            className={`auth-modal-textfield ${
              darkMode && 'auth-modal-textfield-dark'
            }`}
            placeholder="Password"
            name="password"
            value={formValues.password}
            onChange={handleChange}
          />
          <VerticalSpace spacing={20} />
          {viewingLogin ? null : (
            <ConfirmPaswordSection
              value={formValues.confirmPassword}
              handleChange={handleChange}
            />
          )}
          <SubmitEntryButton
            handleSubmit={handleSubmit}
            loading={entryLoading}
            viewingLogin={viewingLogin}
          />
          <FeedbackComponent formError={formError} />
        </div>
      </div>
    </div>
  );
}

export function ViewSwitcherComponent({ handleSwitchView, viewingLogin }) {
  return (
    <div style={{ fontSize: '16px', fontWeight: '400', display: 'flex' }}>
      <div>Already have an account?</div> <HorizontalSpace spacing={4} />{' '}
      <div
        style={{ fontWeight: 'bold', cursor: 'pointer' }}
        onClick={handleSwitchView}
      >
        {viewingLogin ? 'Sign Up' : 'Login'}
      </div>
    </div>
  );
}

export function ConfirmPaswordSection({ value, handleChange }) {
  const { darkMode } = useDarkMode();
  return (
    <div>
      <div style={{ textAlign: 'left' }}>Confirm Password</div>
      <VerticalSpace spacing={10} />
      <TextField
        className={`auth-modal-textfield ${
          darkMode && 'auth-modal-textfield-dark'
        }`}
        placeholder="Confirm Password"
        name="confirmPassword"
        value={value}
        onChange={handleChange}
      />
      <VerticalSpace spacing={20} />
    </div>
  );
}

export function SubmitEntryButton({ handleSubmit, loading, viewingLogin }) {
  const { darkMode } = useDarkMode();
  const content = loading ? (
    <CircularProgress style={{ color: 'white' }} />
  ) : viewingLogin ? (
    'Sign In'
  ) : (
    'Sign Up'
  );
  return (
    <div
      className={`send-email-button ${darkMode && 'send-email-button-dark'}`}
      onClick={handleSubmit}
    >
      {content}
    </div>
  );
}

export function FeedbackComponent({ formError, emailSent }) {
  const errorTextStyle = {
    color: 'red',
    paddingTop: '20px',
    textAlign: 'center',
  };

  return (
    <div>
      {formError !== '' ? (
        <div style={errorTextStyle}>{formError}</div>
      ) : (
        <div></div>
      )}
      <div></div>
    </div>
  );
}

export default UserEntryModal;
