import { AppPage } from '../../components/app_page';
import { HorizontalSpace, VerticalSpace } from '../../components/spacing';
import { Spacer } from '../../components/widgets';
import './landing.css';
import { useMediaQuery } from 'react-responsive';
import { useDarkMode } from '../../components/dark_mode_provider';

import agLogo from '../../images/ag_logo.webp';
import ucfLogo from '../../images/ucf_logo.png';
import headshot from '../../images/raymond-image.webp';

import { FaReact, FaGithub, FaFigma, FaMobile, FaCode } from 'react-icons/fa';
import { IoLogoJavascript, IoLogoFirebase } from 'react-icons/io5';
import { SiFlutter } from 'react-icons/si';
import { BsFillPeopleFill } from 'react-icons/bs';

function Landing() {
  return (
    <AppPage>
      <IntroductionSection />
      <ExperienceSection />
      <MySkillsSection />
    </AppPage>
  );
}

function IntroductionSection() {
  const { darkMode } = useDarkMode();
  const smallScreen = useMediaQuery({ maxWidth: 1190 });
  return (
    <div
      className={`introduction-content  ${
        darkMode && 'introduction-content-dark'
      }`}
    >
      <div className="intro-image-container">
        <img src={headshot} alt="empty" className="intro-image" />
      </div>
      {!smallScreen && <HorizontalSpace spacing={20} />}
      <div className="intro-text-block">
        <div className="landing-title">
          Hi, my name is Raymond Price and I build software!
        </div>
        <VerticalSpace spacing={10} />
        <div>
          I'm passionate about building software, with a primary focus on mobile
          and web application development. I also have experience in various
          other areas of computer science, including machine learning and
          robotics. I take pride in being a very quick learner, always seeking
          new information to expand my knowledge. I thrive in challenging
          situations that push me to further my education and enjoy tackling
          complex problems. Whether it's exploring cutting-edge technologies or
          solving intricate issues, I'm always eager to take on new challenges
          and enhance my skills.
        </div>
      </div>
    </div>
  );
}

function ExperienceSection() {
  const { darkMode } = useDarkMode();
  const smallScreen = useMediaQuery({ maxWidth: 870 });
  return (
    <div
      className={`experience-section ${darkMode && 'experience-section-dark'}`}
    >
      <div className="landing-title">My Experience</div>
      <VerticalSpace spacing={30} />
      <div className="experience-content wrap-reverse">
        <div className="experience-text-container">
          <div className="experience-header">
            Software Development Team Lead
          </div>
          <VerticalSpace spacing={10} />
          <div className="experience-header">Approachable Geek</div>
          <VerticalSpace spacing={10} />
          <div
            className={`experience-dates ${
              darkMode && 'experience-dates-dark'
            }`}
          >
            Aug 2022 - Mar 2024
          </div>
          <VerticalSpace spacing={10} />
          <div
            className={`experience-body ${darkMode && 'experience-body-dark'}`}
          >
            As a software development team lead at Approachable Geek, I had
            hands-on experience developing, releasing, and maintaining
            production software. Specializing in custom mobile and web
            applications, I primarily worked with Flutter. Operating within an
            agile structure, my projects were divided into sprints. My
            responsibilities included overseeing sprint planning, assisting
            teammates with any challenges they faced, and handling the more
            complex development tasks. This role honed my skills in both
            leadership and advanced software development, enabling me to deliver
            high-quality solutions efficiently.
          </div>
        </div>
        {!smallScreen && <HorizontalSpace spacing={50} />}
        <div className="experience-image-container ag-logo">
          <img src={agLogo} alt="logo" className="experience-image" />
        </div>
      </div>{' '}
      {smallScreen && <VerticalSpace spacing={50} />}
      <div className="experience-content">
        <div className="experience-image-container ucf-logo-container">
          <img src={ucfLogo} alt="logo" className="experience-image ucf-logo" />
        </div>
        {!smallScreen && <HorizontalSpace spacing={50} />}
        <div className="experience-text-container">
          <div className="experience-header">Computer Science B.S. Degree</div>
          <VerticalSpace spacing={10} />
          <div className="experience-header">University of Central Florida</div>
          <VerticalSpace spacing={10} />
          <div
            className={`experience-dates ${
              darkMode && 'experience-dates-dark'
            }`}
          >
            Aug 2019 - May 2022
          </div>
          <VerticalSpace spacing={10} />
          <div
            className={`experience-body ${darkMode && 'experience-body-dark'}`}
          >
            My programming journey began at the University of Central Florida,
            where I built a strong foundation in C and Java. As I progressed, I
            expanded my expertise into more advanced languages and specialized
            areas. My coursework was heavily focused on app development, machine
            learning, and robotics, providing me with a comprehensive
            understanding of these fields. Graduating with a BS in Computer
            Science equipped me with a well-rounded skill set, ready to tackle a
            variety of challenges. This education not only solidified my
            technical abilities but also nurtured my passion for continuous
            learning and problem-solving. The diverse projects and rigorous
            curriculum at UCF prepared me to excel in the fast-paced and
            ever-evolving world of software development.
          </div>
        </div>
      </div>
    </div>
  );
}

function MySkillsSection() {
  const { darkMode } = useDarkMode();
  return (
    <div className={`skills-section ${darkMode && 'skills-section-dark'}`}>
      <div className="landing-title">My Skills</div>
      <VerticalSpace spacing={20} />
      <div className="skill-content">
        <div
          className={`skill-container ${darkMode && 'skill-container-dark'}`}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <FaCode size={35} />
            <FaReact size={35} />
            <IoLogoJavascript size={35} />
          </div>
          <div className="skill-header">Web Development</div>
          <div className="skill-body">
            Proficient in web development, I leverage modern technologies and
            AWS services to create responsive and visually stunning websites.
            With a solid foundation in HTML and CSS, I naturally craft engaging
            web interfaces. Using frameworks like React, I seamlessly integrate
            functionalities such as API interactions and database connections.
            Additionally, I am proficient in hosting websites and utilizing AWS
            services, including Lambda for function execution, API Gateway for
            API management, and CloudFront for content delivery.
          </div>
          <Spacer />
          <div className="skill-header">Tech Stack</div>
          <div className="skill-body">
            React - MongoDB - JavaScript - HTML - CSS
          </div>
        </div>
        <div
          className={`skill-container ${darkMode && 'skill-container-dark'}`}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <FaMobile size={35} />
            <SiFlutter size={35} />
            <IoLogoFirebase size={35} />
          </div>
          <div className="skill-header">Full Stack Application Development</div>
          <div className="skill-body">
            Focusing on application development, I have extensive experience in
            this area. I excel at matching designs to create stunning frontends
            in Flutter and connecting them to a NoSQL backend database with
            Firebase. I have developed a wide range of applications, utilizing
            Firebase's cloud functions to ensure security and scalability. Using
            Flutter as a framework, I create easily cross-platform compatible
            apps, allowing for simultaneous development for web, iOS, and
            Android. Additionally, I am well-versed in deploying apps to their
            respective stores.
          </div>
          <Spacer />
          <div className="skill-header">Tech Stack</div>
          <div className="skill-body">
            Flutter - Dart - Firebase - TypeScript - Node
          </div>
        </div>{' '}
        <div
          className={`skill-container ${darkMode && 'skill-container-dark'}`}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <BsFillPeopleFill size={35} />
            <FaGithub size={35} />
            <FaFigma size={35} />
          </div>
          <div className="skill-header">Collaborative Software Development</div>
          <div className="skill-body">
            Working in a professional software development team is second nature
            to me. I have extensive experience managing repositories with
            GitHub, including repository creation, pull request review, and
            setting up CI/CD workflows. Using design software like Figma is also
            routine, ensuring effective collaboration and translating solid
            designs into efficient code. As a team lead in an agile environment,
            I utilize Jira to plan sprints effectively and ensure
            well-thought-out task division.
          </div>
          <Spacer />
          <div className="skill-header">Software Used</div>
          <div className="skill-body">GitHub - Figma - Jira</div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
