import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { initializeDeck, calculateBlackJackHandValue } from '../card';
import { callFunction } from './black_jack_functions';

// Create the context
const BlackjackContext = createContext();

// Blackjack provider component
export const BlackjackProvider = ({ children }) => {
  const [deck, setDeck] = useState([]);
  const [isDeckReady, setIsDeckReady] = useState(false);
  const [isDealersTurn, setIsDealersTurn] = useState(false);
  const [playerHand, setPlayerHand] = useState([]);
  const [dealerHand, setDealerHand] = useState([]);
  const [gameStatus, setGameStatus] = useState('waiting'); // 'playing', 'won', 'lost', 'tied'

  // Function to deal cards
  const dealCard = useCallback(
    (handSetter) => {
      const card = deck.pop();
      handSetter((prevHand) => {
        return [...prevHand, card];
      });
      setDeck(deck);
    },
    [deck]
  );

  // Function to start a new game
  const startNewGame = () => {
    callFunction();
    initializeDeck(setDeck);
    setIsDeckReady(true);
    setPlayerHand([]);
    setDealerHand([]);
    setGameStatus('playing');
  };

  useEffect(() => {
    if (isDeckReady) {
      dealCard(setPlayerHand);
      dealCard(setDealerHand);
      dealCard(setPlayerHand);
      dealCard(setDealerHand);
      setIsDeckReady(false);
    }
  }, [isDeckReady, dealCard]);

  const checkGameStatus = useCallback(() => {
    if (!isDealersTurn) {
      return;
    }
    const playerValue = calculateBlackJackHandValue(playerHand);
    const dealerValue = calculateBlackJackHandValue(dealerHand);

    if (dealerValue > 21 || playerValue > dealerValue) {
      setGameStatus('won');
    } else if (playerValue < dealerValue) {
      setGameStatus('lost');
    } else {
      setGameStatus('tied');
    }
    setIsDealersTurn(false);
  }, [isDealersTurn, playerHand, dealerHand]);

  useEffect(() => {
    if (playerHand.length > 0) {
      const handValue = calculateBlackJackHandValue(playerHand);
      if (handValue > 21) {
        setGameStatus('lost');
      }
    }
  }, [playerHand]);

  useEffect(() => {
    if (dealerHand.length <= 0 || !isDealersTurn) {
      return;
    }
    const handValue = calculateBlackJackHandValue(dealerHand);
    if (handValue > 17) {
      checkGameStatus();
    } else {
      dealCard(setDealerHand);
    }
  }, [dealerHand, isDealersTurn, checkGameStatus, dealCard]);

  // Function to handle player's move (hit or stand)
  const playerMove = (move) => {
    if (move === 'hit') {
      dealCard(setPlayerHand);
    } else if (move === 'stand') {
      setIsDealersTurn(true);
      dealerMove();
    }
  };

  // Function to handle dealer's move
  const dealerMove = () => {
    const handValue = calculateBlackJackHandValue(dealerHand);
    if (handValue < 17) {
      dealCard(setDealerHand);
    } else {
      checkGameStatus();
    }
  };

  return (
    <BlackjackContext.Provider
      value={{
        deck,
        playerHand,
        dealerHand,
        gameStatus,
        startNewGame,
        playerMove,
        calculateBlackJackHandValue,
      }}
    >
      {children}
    </BlackjackContext.Provider>
  );
};

// Custom hook to use the Blackjack context
export const useBlackjack = () => useContext(BlackjackContext);
