import TextField from '../../components/text_field';
import { VerticalSpace } from '../../components/spacing';
import React, { useState } from 'react';
import { AppPage } from '../../components/app_page';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useDarkMode } from '../../components/dark_mode_provider';

function Contact() {
  const [formError, setFormError] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const { darkMode } = useDarkMode();

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const checkFormErrors = () => {
    if (formValues.name === '') {
      setFormError('You must provide your name!');
      return true;
    }
    if (formValues.email === '') {
      setFormError('You must provide your email!');
      return true;
    }
    if (formValues.message === '') {
      setFormError('You must provide a message!');
      return true;
    }
    setFormError('');
    return false;
  };

  const handleChange = (event) => {
    if (formError !== '') {
      setFormError('');
    }
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkFormErrors()) {
      return;
    }

    setEmailSending(true);
    axios
      .post(
        'https://y5f9kvejt2.execute-api.us-east-2.amazonaws.com/prod/send-email',
        { body: JSON.stringify(formValues) },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        console.log(`Email sent successfully`);
        setEmailSuccess(true);
        setEmailSending(false);
      })
      .catch((error) => {
        setFormError('There was a problem sending the email, please try again');
        setEmailSending(false);
        console.error(`Error sending email: ${error}`);
      });
  };

  return (
    <AppPage>
      <div
        className={`contact-container  ${darkMode && 'contact-container-dark'}`}
      >
        <div style={{ fontSize: '32px', fontWeight: '500' }}>Contact Me</div>
        <VerticalSpace spacing={20} />
        <div style={{ fontSize: '16px', fontWeight: '400' }}>
          Want to get in touch? Submit the form below and I will respond as soon
          as possible
        </div>
        <VerticalSpace spacing={50} />
        <div className={`contact-card ${darkMode && 'contact-card-dark'}`}>
          <div>Name</div>
          <VerticalSpace spacing={10} />
          <TextField
            className={`contact-textfield ${
              darkMode && 'contact-textfield-dark'
            }`}
            placeholder="Full Name"
            name="name"
            value={formValues.name}
            onChange={handleChange}
          />
          <VerticalSpace spacing={20} />
          <div>Email</div>
          <VerticalSpace spacing={10} />
          <TextField
            className={`contact-textfield ${
              darkMode && 'contact-textfield-dark'
            }`}
            placeholder="Email Address"
            name="email"
            value={formValues.email}
            onChange={handleChange}
          />
          <VerticalSpace spacing={20} />
          <div>Message</div>
          <VerticalSpace spacing={10} />
          <textarea
            className={`contact-textfield ${
              darkMode && 'contact-textfield-dark'
            } large-textfield`}
            placeholder="Message"
            name="message"
            value={formValues.message}
            onChange={handleChange}
          />
          <VerticalSpace spacing={20} />
          <SendEmailButton
            handleSubmit={handleSubmit}
            loading={emailSending}
            emailSent={emailSuccess}
          />
          <FeedbackComponent formError={formError} emailSent={emailSuccess} />
        </div>
      </div>
    </AppPage>
  );
}

export function SendEmailButton({ handleSubmit, loading, emailSent }) {
  const { darkMode } = useDarkMode();
  const hidenDisplay = emailSent ? { display: 'none' } : {};
  const content = emailSent ? (
    <div style={hidenDisplay}></div>
  ) : loading ? (
    <CircularProgress style={{ color: 'white' }} />
  ) : (
    'Send Email'
  );
  return (
    <div
      className={`send-email-button ${darkMode && 'send-email-button-dark'}`}
      style={hidenDisplay}
      onClick={handleSubmit}
    >
      {content}
    </div>
  );
}

export function FeedbackComponent({ formError, emailSent }) {
  const errorTextStyle = {
    color: 'red',
    paddingTop: '20px',
    textAlign: 'center',
  };

  return (
    <div>
      {emailSent ? (
        <div style={{ textAlign: 'center' }}>Email sent successfully!</div>
      ) : formError !== '' ? (
        <div style={errorTextStyle}>{formError}</div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Contact;
