import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

export class CloudFunctions {
  static async callFunction({ functionName, params }) {
    const callable = httpsCallable(functions, functionName);

    const result =
      params === undefined ? await callable() : await callable(params);

    return result;
  }

  static async userSignUp(params) {
    const result = await CloudFunctions.callFunction({
      functionName: 'userSignUp',
      params: params,
    });
    return result;
  }
}
