import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase'; // Adjust the path as necessary

export const callFunction = async () => {
  try {
    const helloWorld = httpsCallable(functions, 'helloWorld');
    const result = await helloWorld();
    console.log(result.data); // Should log "Hello from Firebase!"
  } catch (error) {
    console.error('Error calling function:', error);
  }
};
