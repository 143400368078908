import './navbar_styles.css';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import Hamburger from 'hamburger-react';
import { HorizontalSpace } from '../spacing';
import { useSpring, animated } from '@react-spring/web';
import { BsSunFill } from 'react-icons/bs';
import { FaMoon } from 'react-icons/fa';
import { useDarkMode } from '../dark_mode_provider';
// import { useAuth } from '../../providers/auth_provider';

const Navbar = ({ handleOpenAuthModal }) => {
  const [isNavWindowOpen, setNavWindowOpen] = useState(false);
  const navRef = useRef(null);

  const handleNavLinkClick = () => {
    setNavWindowOpen(false);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNavWindowOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar" ref={navRef}>
      <div className="nav-container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DarkModeIcon />
          <HorizontalSpace spacing={25} />
          <div className="navbar-name">Raymond Price</div>
        </div>
        <div style={{ flex: 1 }} />

        <div className="menu-icon">
          <Hamburger
            toggled={isNavWindowOpen}
            toggle={setNavWindowOpen}
            color="white"
            size={25}
          />
        </div>
        <div className={`nav-elements  ${isNavWindowOpen && 'active'}`}>
          <ul>
            <li>
              <NavLink to="/" onClick={handleNavLinkClick}>
                About me
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink to="/projects" onClick={handleNavLinkClick}>
                Projects
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink to="/contact" onClick={handleNavLinkClick}>
                Contact
              </NavLink>
            </li>
          </ul>
          {/* <ul>
            <li>
              <NavLink to="/games" onClick={handleNavLinkClick}>
                Games
              </NavLink>
            </li>
          </ul>

          <ul>
            <li>
              <EntryOrLogoutButton handleOpenAuthModal={handleOpenAuthModal} />
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

// function EntryOrLogoutButton({ handleOpenAuthModal }) {
//   const { currentUser, handleLogout } = useAuth();
//   return (
//     <div
//       onClick={currentUser === null ? handleOpenAuthModal : handleLogout}
//       className="entry-button"
//     >
//       {currentUser === null ? 'Sign Up' : 'Logout'}
//     </div>
//   );
// }

function DarkModeIcon() {
  const [hovered, setHovered] = useState(false);
  const { darkMode, toggleDarkMode } = useDarkMode();

  const iconSize = 30;

  // Define the animation for heart icon
  const sunProps = useSpring({
    transform: hovered ? 'rotate(180deg)' : 'rotate(0deg)',
    opacity: darkMode ? (hovered ? 1 : 0) : hovered ? 0 : 1,
    config: { tension: 50, friction: 10 },
  });

  // Define the animation for star icon
  const moonProps = useSpring({
    transform: darkMode
      ? hovered
        ? 'rotate(180deg)'
        : 'rotate(0deg)'
      : hovered
      ? 'rotate(0deg)'
      : 'rotate(180deg)',
    opacity: darkMode ? (hovered ? 0 : 1) : hovered ? 1 : 0,
    config: { tension: 50, friction: 10 },
  });

  return (
    <div
      onClick={() => toggleDarkMode()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        position: 'relative',
        width: iconSize,
        height: iconSize,
        cursor: 'pointer',
      }}
    >
      <animated.div
        style={{
          ...moonProps,
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <FaMoon size={iconSize} color="white" />
      </animated.div>
      <animated.div
        style={{
          ...sunProps,
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <BsSunFill size={iconSize} color="white" />
      </animated.div>
    </div>
  );
}

export default Navbar;
