import { AppPage } from '../../components/app_page';
import '../../components/app_styles.css';
import './projects.css';

import idafanLogo from '../../images/idafan.webp';
import coreLogo from '../../images/core_logo.svg';
import buzzcuttLogo from '../../images/buzzcutt.webp';
import aysLogo from '../../images/ays_logo.webp';
import scoutTraksLogo from '../../images/scout_traks.png';
import mapifiLogo from '../../images/mapi-fi_logo.svg';
import { useDarkMode } from '../../components/dark_mode_provider';

function Projects() {
  const idafanLink = 'https://www.approachablegeek.com/work/idafan';
  const coreLink = 'https://www.approachablegeek.com/work/core-anesthesia';
  const buzzcuttLink = 'https://www.approachablegeek.com/work/buzz-cutt';
  const scoutTraksLink = 'https://www.approachablegeek.com/work/scout-traks';
  const mapifiLink = 'https://www.approachablegeek.com/work/mapi-fi';
  const aysLink = '/projects/ays';

  const { darkMode } = useDarkMode();
  return (
    <AppPage>
      <div className={`wrapper ${darkMode && 'wrapper-dark'}`}>
        <div className="projects-grid">
          <ProjectShowCase
            logo={mapifiLogo}
            link={mapifiLink}
            displayName={'Mapi-fi'}
            additionalStyling={'mapifi-additions image-shadow'}
            additionalImageStyling={'smaller-image'}
          />
          <ProjectShowCase
            logo={buzzcuttLogo}
            link={buzzcuttLink}
            displayName={'BuzzCutt'}
          />
          <ProjectShowCase
            logo={scoutTraksLogo}
            link={scoutTraksLink}
            displayName={'Scout Traks'}
            additionalStyling={'image-shadow'}
          />
          <ProjectShowCase
            logo={idafanLogo}
            link={idafanLink}
            displayName={'Idafan'}
          />
          <ProjectShowCase
            logo={coreLogo}
            link={coreLink}
            displayName={'Core Anesthesia'}
          />
          <ProjectShowCase
            logo={aysLogo}
            link={aysLink}
            displayName={'At Your Service Payroll'}
            additionalStyling={'image-shadow'}
            dontOpenWindow={true}
          />
        </div>
      </div>
    </AppPage>
  );
}

function ProjectShowCase({
  logo,
  link,
  displayName,
  dontOpenWindow,
  additionalStyling,
  additionalImageStyling,
}) {
  const { darkMode } = useDarkMode();
  return (
    <div style={{ height: '370px' }}>
      <div className={`project-container ${additionalStyling}`}>
        <img
          src={logo}
          alt="empty"
          className={`project-image ${additionalImageStyling}`}
        />
        <ViewCaseStudyButton
          urlLink={link}
          dontOpenWindow={dontOpenWindow ?? false}
        />
      </div>
      <div className={`project-title ${darkMode && 'project-title-dark'}`}>
        {displayName}
      </div>
    </div>
  );
}

function ViewCaseStudyButton({ urlLink, dontOpenWindow }) {
  const { darkMode } = useDarkMode();
  return (
    <a
      className={`hover-content ${darkMode && 'hover-content-dark'}`}
      href={urlLink}
      target={dontOpenWindow === true ? '' : '_blank'}
      rel={dontOpenWindow === true ? '' : 'noopener noreferrer'}
    >
      View Case Study
    </a>
  );
}

export default Projects;
