import { GiClubs, GiSpades, GiDiamonds, GiHearts } from 'react-icons/gi';
import React, { useState } from 'react';

export class Card {
  constructor(value, suit) {
    this.value = value;
    this.suit = suit;
  }

  isEqual(card) {
    return this.value === card.value && this.suit === card.suit;
  }

  toString() {
    return `${this.value} of ${this.suit}`;
  }
}

export const CardComponent = ({ value, suit }) => {
  const [hovering, setHovering] = useState(false);
  const cardStyle = {
    width: '150px',
    height: '190px',
    backgroundColor: 'white',
    position: 'relative',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.08)',
    zIndex: hovering ? 10 : 1,
  };
  const valueStyle = {
    position: 'absolute',
    top: 14,
    left: 14,
    fontWeight: 500,
    fontSize: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  };
  const suitStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div style={valueStyle}>
        {value === 'T' ? 10 : value}
        <div>{getSuitComponent(suit, 20)}</div>
      </div>
      <div style={suitStyle}>{getSuitComponent(suit)}</div>
    </div>
  );
};

function getSuitComponent(suit, suitSize) {
  const size = suitSize ?? 30;
  switch (suit) {
    case 'H':
      return <GiHearts size={size} color="ff3b30"></GiHearts>;
    case 'D':
      return <GiDiamonds size={size} color="ff3b30"></GiDiamonds>;
    case 'C':
      return <GiClubs size={size}></GiClubs>;
    case 'S':
      return <GiSpades size={size}></GiSpades>;
    default:
      return <div>Error</div>;
  }
}

export const initializeDeck = (setDeck) => {
  const suits = ['H', 'D', 'C', 'S'];
  const values = [
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'T',
    'J',
    'Q',
    'K',
    'A',
  ];

  let newDeck = [];
  for (let suit of suits) {
    for (let value of values) {
      newDeck.push(new Card(value, suit));
    }
  }
  newDeck = shuffleDeck(newDeck);
  setDeck(newDeck);
};

const shuffleDeck = (deck) => {
  for (let i = deck.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [deck[i], deck[j]] = [deck[j], deck[i]];
  }
  return deck;
};

export const calculateBlackJackHandValue = (hand) => {
  let value = 0;
  let aceCount = 0;

  hand.forEach((card) => {
    if (card === undefined) {
      return;
    }
    const cardValue = card.value;
    if (cardValue === 'A') {
      aceCount += 1;
      value += 11;
    } else if (['T', 'J', 'Q', 'K'].includes(cardValue)) {
      value += 10;
    } else {
      value += parseInt(cardValue, 10);
    }
  });

  // Adjust for aces
  while (value > 21 && aceCount > 0) {
    value -= 10;
    aceCount -= 1;
  }

  return value;
};
