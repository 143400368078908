import './ays.css';

import aysLogo from '../../../../images/ays_logo.webp';
import { VerticalSpace } from '../../../../components/spacing';
import { useDarkMode } from '../../../../components/dark_mode_provider';

import aysScreenshot1 from '../../../../images/ays/ays_screenshot1.webp';
import aysScreenshot2 from '../../../../images/ays/ays_screenshot2.webp';
import aysScreenshot3 from '../../../../images/ays/ays_screenshot3.webp';
import aysScreenshot4 from '../../../../images/ays/ays_screenshot4.webp';

import { SiFlutter, SiDart, SiTypescript } from 'react-icons/si';
import { IoLogoFirebase } from 'react-icons/io5';
import { FaFigma, FaNode } from 'react-icons/fa';

function AYS() {
  const { darkMode } = useDarkMode();
  return (
    <div className={`ays-container ${darkMode && 'ays-container-dark'}`}>
      <div className="ays-header">AYS Employee Leasing</div>
      <div className="ays-body-image">
        <div className="ays-body">
          AYS Employee Leasing simplifies time tracking for both employees and
          employers with its intuitive features. For employees, the app offers
          seamless clock-in and clock-out functionalities, along with a clear
          overview of weekly work hours. This empowers employees to manage their
          schedules efficiently and stay informed about their working hours. On
          the employer side, AYS Employee Leasing enhances workforce management
          by providing easy access to and editing of employee hours. Employers
          can efficiently monitor work hours without the hassle of traditional
          time sheets, facilitating streamlined payroll processing and ensuring
          accurate records. The app's user-friendly interface improves
          productivity and transparency, benefiting both employees and employers
          by simplifying time management and optimizing operational efficiency.
        </div>
        <div>
          <img src={aysLogo} alt="empty" className="ays-image" />
        </div>
      </div>
      <div className="ays-screenshots">
        <img src={aysScreenshot1} alt="empty" className="ays-screenshot" />
        <img src={aysScreenshot2} alt="empty" className="ays-screenshot" />
        <img src={aysScreenshot3} alt="empty" className="ays-screenshot" />
        <img src={aysScreenshot4} alt="empty" className="ays-screenshot" />
      </div>
      <VerticalSpace spacing={20} />
      <div className="ays-header">Tools Used</div>
      <div className="tool-widgets">
        <ToolWidget Icon={SiFlutter} label={'Flutter'} />
        <ToolWidget Icon={SiDart} label={'Dart'} />
        <ToolWidget Icon={IoLogoFirebase} label={'Firebase'} />
        <ToolWidget Icon={SiTypescript} label={'TypeScript'} />
        <ToolWidget Icon={FaNode} label={'Node'} />
        <ToolWidget Icon={FaFigma} label={'Figma'} />
      </div>
      <VerticalSpace spacing={30} />
    </div>
  );
}

function ToolWidget({ Icon, label }) {
  const { darkMode } = useDarkMode();
  return (
    <div className={`tool-widget  ${darkMode && 'tool-widget-dark'}`}>
      {Icon && <Icon />}
      <div>{label}</div>
    </div>
  );
}

export default AYS;
