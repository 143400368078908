import React from 'react';
// import { motion } from 'framer-motion';

export function AppPage({ children }) {
  return <div>{children}</div>;
}

// const pageTurnVariants = {
//   initial: {
//     opacity: 0,
//     rotateY: -90,
//   },
//   in: {
//     opacity: 1,
//     rotateY: 0,
//   },
//   out: {
//     opacity: 0,
//     rotateY: 90,
//   },
// };

// const pageTransition = {
//   type: 'spring',
//   stiffness: 100,
//   damping: 20,
//   duration: 0.5,
// };

// function PageTurnAnimation({ children }) {
//   return (
//     <motion.div
//       initial="initial"
//       animate="in"
//       exit="out"
//       variants={pageTurnVariants}
//       transition={pageTransition}
//       style={{
//         perspective: 1000,
//         transformStyle: 'preserve-3d',
//       }}
//     >
//       <div
//         style={{
//           backfaceVisibility: 'hidden',
//           position: 'relative',
//         }}
//       >
//         {children}
//       </div>
//     </motion.div>
//   );
// }
